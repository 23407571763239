.App {
  text-align: center;
}

.App-navbar {
  transform: skew(-20deg);
  border: none;
}

.App-navbar span {
  display: block;
  transform: skew(20deg);
}

.App-navbar > .nav-item {
  border: 1px solid;
  border-color: var(--dark);
  color: white;
  background-color: var(--secondary);
}
.App-navbar > .nav-item.active {
  color: white;
  border-color: var(--dark);
  background-color: var(--primary);
}
.App-navbar > .nav-item:hover {
  border-color: var(--secondary);
}
.App-navbar > .nav-item.active:hover {
  border-color: var(--primary);
}

.border-3 {
  border-width: 3px !important;
}
