:root {
  --header-img-width: 170px;
}
.Header {
  position: relative;
  background-color: #282c34;
  text-align: center;
  color: white;
  border-bottom: solid var(--primary);
  padding-top: 25px;
}

.Header-lang-img {
  height: 25px;
  width: 37px;
}

@media only screen and (max-width: 360px) {
  .Header-lang-img {
    height: calc(25px / 2);
    width: calc(37px / 2);
  }
}

.Header h1 {
  font-size: 2em;
}

.Header h2 {
  font-size: 1.2em;
  margin-bottom: 110px;
}

.Header-img-container {
  position: absolute;
  bottom: calc(-1 * var(--header-img-width) / 2);
  left: 50%;
  margin-left: calc(-1 * var(--header-img-width) / 2);
  z-index: 999;
}

.Header-img {
  width: var(--header-img-width);
}
