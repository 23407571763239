.Aboutme-card {
  margin: 10px 0 20px 0;
  text-align: center;
}

.Aboutme-card .card-text {
  padding: 0 5%;
}

.Aboutme-social {
  margin: 85px 0 20px 0;
}

.Aboutme-social-button {
  height: 35px !important;
  width: 35px !important;
}
.Aboutme-social a.Aboutme-social-button {
  margin-right: 10px;
}

.Aboutme-social a.Aboutme-social-button:last-child {
  margin-right: 0px;
}
